# Recent activity form handler, for 2015 theme
namespace 'Slzr', (exports, top) ->
  $ = Slzr.jQuery

  # Control the hiding/showing of the forms for submitting recent activity
  class exports.RecentActivity
    constructor: (wrapper_element) ->
      @list = $(wrapper_element)

      # Attach event handlers
      @list.find('.add_review_button').click (ev) => @showReviewForm(ev)
      @list.find('.add_comment_button').click (ev) => @showCommentForm(ev)
      @list.find('.add_photo_button').click (ev) => @showPhotoForm(ev)
      @list.find('.cancel_button').click => @hideAllForms()

      @list.find('textarea').each (i, el) =>
        el.placeholder_value = $(el).val()
        $(el).blur (ev) => @inputBlurHandler(ev)

      # Attach validation handler for comment form
      @commentForm = @list.find('form[data-recent-activity="comment"]')
      @commentForm?.on 'submit', (evt) => @validateCommentForm(evt)

      # Attach validation and rating handlers for review form
      @reviewForm = @list.find('form[data-recent-activity="review"]')
      if @reviewForm?
        @reviewForm.on 'submit', (evt) => @validateReviewForm(evt)
        # Handle clicks on the submit buttons to update the rating value
        self = this
        @reviewForm.on 'click', 'input[data-review-rating]', (evt) ->
          self.reviewForm.find('[name="rating"]').val $(this).data('review-rating')
          $(this).data('ujs:enable-with', $(this).val()).val('Posting...')

      # Set up multi-upload form
      @photoForm = @list.find('form[data-recent-activity="photo"]')
      if @photoForm?
        input = @photoForm.find('[data-multi-upload="input"]')
        container = @photoForm.find('[data-multi-upload="input-container"]')
        file_list = @photoForm.find('[data-multi-upload="list"]')

        new exports.MultiUploadSelector
          maximum: 4
          item_container_tag: 'fieldset'
          template: JST['upload_recent_activity']
          input_container: container
          input: input
          file_list: file_list


    # Automatically hide the form after the input field loses focus, after a short delay.
    inputBlurHandler: (ev) ->
      value = $(ev.target).val()
      if value == '' or value == ev.target.placeholder_value
        @blur_timer = _.delay _.bind(@hideAllForms, this), 150

      false

    showReviewForm: (ev) ->
      @hideAllForms()
      @showForm 'review', ev
      false

    showCommentForm: (ev) ->
      @hideAllForms()
      @showForm 'comment', ev
      false

    showPhotoForm: (ev) ->
      @hideAllForms()
      @showForm 'photo', ev
      false

    hideAllForms: ->
      if @blur_timer
        clearTimeout(@blur_timer)
        @blur_timer = null

      id = @list[0].id
      @list.find("##{id}_new_comment, ##{id}_new_review, ##{id}_new_photo").hide()
      @list.find('.link_action').show()
      false

    showForm: (form, ev) ->
      if $(ev.target).is '.disabled'
        ev.preventDefault()
        return

      id = "##{@list[0].id}_new_#{form}"
      @list.find(id).show()
      @list.find('.link_action').hide()
      @list.find("textarea##{form}_#{@list[0].id}").focus()

    # Validation functions
    validateCommentForm: (evt) =>
      textarea = @commentForm.find("textarea#comment_#{@list[0].id}")
      val = textarea.val()

      if val.isBlank()
        alert('You must provide a comment!')
        evt.stopImmediatePropagation()
        evt.preventDefault()

    validateReviewForm: (evt) =>
      textarea = @reviewForm.find('textarea')
      val = textarea.val()

      if val.isBlank()
        alert('You must provide a review!')
        evt.stopImmediatePropagation()
        evt.preventDefault()

  # Utility functions for resetting the comment/review form
  exports.Comments =

  class exports.Comments
    @success: (the_form) ->
      form = $(the_form)
      form.find('input[type="text"], textarea').val(null)
      form.find('input[type="radio"], input[type="checkbox"]').prop('checked', false)
      form.find('input[type="submit"]').each ->
        $this = $(this)
        $this.prop 'disabled', false
        original_value = $this.attr('originalValue')
        original_value or= $this.data('ujs:enable-with')
        $this.val(original_value) if original_value?

  # Attach for form event handlers
  jQuery ($) ->
    $(document).delegate 'form[data-recent-activity]', 'ajax:success', (ev, data) ->
      form = $(ev.target)
      form_wrapper = form.closest('[data-recent-activity="form-wrapper"]')
      recent_activity_wrapper = form.closest('[data-recent-activity="wrapper"]')

      exports.Comments.success form
      form_wrapper.hide()
      recent_activity_wrapper.find('.link_action').show()

    # Enable recent activity on anything on-page
    $('[data-recent-activity="wrapper"][data-recent-activity-enabled]').each -> new exports.RecentActivity(this)


# Theme adapter for recent activity page updates
namespace 'Slzr.Theme.RecentActivity', (exports, top) ->
  $ = jQuery = top.Slzr.jQuery

  # Add item to the recent activity list
  #
  # options has:
  #   container: dom id (prefix) of listing
  #   item: rendered html for the item row
  exports.addItem = (options) ->
    container = options.container
    item = options.item

    $("##{container}_list").prepend(item)
    $("##{container}_list .nocontent").remove()

  # Remove an item from the recent activity list
  #
  # options:
  #   container: dom id (prefix) of listing
  #   item: id of element to remove (no leading #)
  exports.removeItem = (options) ->
    container = options.container
    item = options.item

    $("##{item}").remove()

  # Append more activity to the bottom of the list
  exports.appendActivity = (data) ->
    $('#' + data.container).append(data.content)

  exports.updateMoreActivityLink = (data) ->
    $('#' + data.id).html(data.content)
