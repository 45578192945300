# Interface for event button state change, on event/show
#
# This is the version for legacy theme.

namespace 'Slzr.Theme', (exports, top) ->
  $ = jQuery = Slzr.jQuery

  # Called by the server in response to event attendance changes
  #
  # options hash:
  #   is_listing: true if in response to a button on an event listing, false otherwise
  #   event_id: ID of event changed
  #   event_instance_id: ID of event instance changed, if known. (detail page "unconfirm" has no ID)
  #   attending: new attendance state
  #
  # for listings:
  #   wrapper_id: for is_listing, dom element to replace with button contents
  #   button_contents: contents for listing action buttons
  #   lightbox_content: content to show in a lightbox after (listing only)
  #
  # for detail page:
  #   confirm_buttons: new content for event_buttons area
  #   attendees: new content for attendees block
  #   status: new content for event status area
  #   hide_lightbox: should a visible lightbox be closed
  exports.eventAttendanceChanged = (options) ->
    if options.is_listing
      # Interested/not interested button on event listings
      $("##{options.wrapper_id}").replaceWith(options.button_contents)
      Slzr.Lightbox.open(content: options.lightbox_content) if options.lightbox_content

    else
      # Interested/not interested button on event detail page
      $('#x-confirm-buttons-wrapper').html options.confirm_buttons
      $('#x-event-attendees').html options.attendees
      $('#x-event-status').replaceWith options.status
      if options.hide_lightbox
        Slzr.Lightbox.close()
      else
        Slzr.Lightbox.open(content: options.lightbox_content) if options.lightbox_content

      $(document).trigger 'slzr:attendance:change'

