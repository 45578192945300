 # ItemPager, for rotating through items
 #
 # This is the "Featured Events" Carousel for the old theme.

 namespace 'Slzr', (exports, top) ->
   $ = Slzr.jQuery

   # Carousel implementation for featured event boxes
   #
   # The basic idea is animating the left property from 100% to 0% (or 0% to -100%), then swapping classes and clearing
   # the left property.
   #
   # For convenience, all items are assigned the next class to position them just off to the right of the selected item.
   #
   # When not animating, all extra items are additionally assigned the "inactive" class to hide them, preventing them
   # from receving keyboard focus.
   #
   # When switching, the next item is set to visible (by removing the "inactive" class), while animating the current
   # item to left:-100%, and the next item to left:0. "active" class is then assigned to the next item, and
   # "next inactive" classes are assigned to the previous one, while also clearing the left property.
   class ItemPager
     # Class assigned to the currently shown item
     active_class:   'active'
     # Class assigned to items that aren't visible (not current and not animating in)
     inactive_class: 'inactive'
     # Class assigned to the next shown item to the right
     next_class:     'next'
     # Class assigned to the previous shown item to the left
     previous_class: 'previous'

     constructor: (container, options) ->
       defaults =
         delay: 5
         autostart: true

       @options = $.extend defaults, options

       @container = $(container)
       return unless @container.length > 0

       # Initialize various setups
       @slides = @container.children()
       @nav_links = []
       @current_index = 0
       @animating = false
       @num_slides = @slides.length

       @createNavigation() if @num_slides > 1
       @initializeState()
       @setHeight()
       @startTimer() if @options.autostart

       $(window).on 'resize', @setHeight

     initializeState: ->
       @container.css(position: 'relative')

       # Set the first slide active, and all others to next
       @slides.addClass(@next_class).addClass(@inactive_class).removeClass(@active_class)
       $(@slides[0]).removeClass(@next_class).removeClass(@inactive_class).addClass(@active_class)

     # Fix the height to the max slide height
     #
     # Should also be called on resize
     setHeight: =>
       max_height = 0
       @slides.each ->
         height = $(this).outerHeight(false)
         max_height = height if max_height < height

       @container.outerHeight(max_height)

     # Prepare the next item, by assigning it the next class
     prepareNext: (index) ->
       $(@slides[index]).toggleClass(@next_class, true).toggleClass(@inactive_class, false)

     # Perform cleanup after animating, by removing classes and
     finalizeSlide: (index, old_index) ->
       slide = $(@slides[index])
       old_slide = $(@slides[old_index])

       # Set current card active, and the previous card to next
       slide.addClass(@active_class).removeClass("#{@next_class} #{@inactive_class} #{@previous_class}").css('left', '')
       old_slide.addClass("#{@next_class} #{@inactive_class}").removeClass("#{@previous_class} #{@active_class}").css('left', '')

     setVisibleSlide: (index) ->
       return if index == @current_index
       @prepareNext index

       slides = $().add(@slides[index]).add(@slides[@current_index])
       slides.animate {left: '-=100%'},
         duration: 'slow'
         easing: 'swing'
       .promise().then =>
         @finalizeSlide(index, @current_index)
         @current_index = index
         @updateNavigation()

     # Show the next item
     nextItem: ->
       old_index = @current_index
       new_index = @current_index + 1
       # Loop around
       new_index = 0 if new_index >= @num_slides

       @setVisibleSlide(new_index)

     previousItem: ->
       new_index = @current_index - 1
       new_index = @num_slides - 1 if new_index <= 0
       @setVisibleSlide(new_index)

     createNavigation: ->
       @navigation = $('<div/>', {class: 'featured_nav'})

       @nav_links = []
       for i in [0..@num_slides - 1]
         link = $('<a/>', {href: '#'}).html(i + 1).data('index', i)
         @nav_links.push link
         @navigation.append link
         @navigation.append ' '

         link.click (event) =>
           @navigationClick($(event.target).data('index'))

       @navigation.insertAfter(@container)
       @updateNavigation()

     updateNavigation: ->
       current_link = @nav_links[@current_index]
       if current_link
         $(el).removeClass('current').attr('aria-current', null) for el in @nav_links
         $(current_link).addClass('current').attr('aria-current', true)

     navigationClick: (index, event) ->
       return false if @navingTo == index

       @navingTo = index
       @stopTimer()
       @setVisibleSlide index
       false

     timerFunction: ->
       @nextItem()
       @startTimer()

     startTimer: ->
       @stopTimer()
       @timer = setTimeout((=> @timerFunction()), @options.delay * 1000)

     stopTimer: ->
       if @timer
         clearTimeout @timer
         @timer = null


   exports.ItemPager = ItemPager

   # Activate sliders on page load
   $ ($) ->
     $('div[data-featured-section="true"]').each ->
       container = this
       current_pager = null

       $('div[data-rotate="true"]', container).each ->
         $this = $(this);

         wrapper_div = $this.closest('div[data-featured-section="true"]')

         tab_div = $this.closest('div.tabs-component-panel')
         tab_div = wrapper_div unless tab_div.length > 0
         tab_div.data 'item_pager', new ItemPager(this, autostart: false)

         if !current_pager && tab_div.is(':visible')
           current_pager = tab_div
           current_pager.data('item_pager').startTimer()

     $(document).bind 'slzr:tabshown', (event, memo) ->
       if pager = memo.tab.data('item_pager')
         pager.setHeight()
         pager.startTimer()

     $(document).bind 'slzr:tabhidden', (event, memo) ->
       if pager = memo.tab.data('item_pager')
         pager.stopTimer()
