# Event handlers for the event attendance buttons
#
# data-action is:
#   event-going -> I'm Interested
#   event-not-going -> Not Interested
#   event-watch -> Watch
#   event-unwatch -> Unwatch
#   event-change-attendance -> Display attendance popup
#
# Gets event id either from data-event-id or meta localist-event-id

namespace 'Slzr.UI.EventButtons', (exports, top) ->
  $ = Slzr.jQuery

  ## URLS ##

  # URL for the multi-date going lightbox
  EVENT_GOING_URL = "/event/@@EVENT_ID@@/going"

  # URL for confirming attendance
  EVENT_CONFIRM_URL = "/event/@@EVENT_ID@@/confirm"
  EVENT_UNCONFIRM_URL = "/event/@@EVENT_ID@@/unconfirm"
  EVENT_WATCH_URL = "/event/@@EVENT_ID@@/watch"
  EVENT_UNWATCH_URL = "/event/@@EVENT_ID@@/unwatch"
  EVENT_INVITE_URL = "/event/@@EVENT_ID@@/invite_friends"

  getEventId = -> $(this).data('event-id') || Slzr.Meta['event-id']

  # I'm going button
  $(document).onAction 'event-going', ->
    event_id = getEventId()
    if event_id?
      url = EVENT_CONFIRM_URL.replace('@@EVENT_ID@@', event_id)
      options = {}
      if $(this).data('invitation') && $(this).data('invitation-token')
        options.invite = $(this).data('invitation')
        options.token = $(this).data('invitation-token')
      $.post url, options
        .done (event, xhr, settings) ->
          $(document).trigger 'slzr:attendance:change'

  $(document).onAction 'event-not-going', ->
    event_id = getEventId()
    if event_id?
      url = EVENT_UNCONFIRM_URL.replace('@@EVENT_ID@@', event_id)
      specified_date = $(this).data('attendance-date')
      options = {
        date: specified_date
      }
      if $(this).data('invitation') && $(this).data('invitation-token')
        options.invite = $(this).data('invitation')
        options.token = $(this).data('invitation-token')
      $.post url, options
        .done (event, xhr, settings) ->
          $(document).trigger 'slzr:attendance:change'

  $(document).onAction 'event-watch', ->
    event_id = getEventId()
    if event_id?
      url = EVENT_WATCH_URL.replace('@@EVENT_ID@@', event_id)
      $.post url
        .done (event, xhr, settings) ->
          $(document).trigger 'slzr:attendance:change'

  $(document).onAction 'event-unwatch', ->
    event_id = getEventId()
    if event_id?
      url = EVENT_UNWATCH_URL.replace('@@EVENT_ID@@', event_id)
      $.post url
        .done (event, xhr, settings) ->
          $(document).trigger 'slzr:attendance:change'

  $(document).onAction 'event-change-attendance', ->
    event_id = getEventId()
    if event_id?
      url = EVENT_GOING_URL.replace('@@EVENT_ID@@', event_id)
      openIbox(url)

  $(document).onAction 'event-invite', ->
    event_id = getEventId()
    if event_id?
      url = EVENT_INVITE_URL.replace('@@EVENT_ID@@', event_id)
      openIbox(url)
